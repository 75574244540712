export function setViewportHeightAdjuster(): void {
  const vh = window.innerHeight * 0.01;

  if (document.documentElement.style.getPropertyValue('--vh')) {
    return;
  }

  document.documentElement.style.setProperty('--vh', `${vh / 16}rem`);

  window.addEventListener(
    'resize',
    () => {
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh / 16}rem`);
    },
    { passive: false },
  );
}
