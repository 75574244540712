// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.LoadingDots__loading-dots--xm7S_ {
  display: flex;
  position: relative;
}

.LoadingDots__dot--bHht8 {
  display: block;
  width: 0.625rem;
  height: 0.625rem;
  margin-right: 0.3125rem;
  background: var(--black);
  border-radius: 50%;
  animation-name: LoadingDots__loading-dots--xm7S_;
  animation-duration: 1200ms;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
}

.LoadingDots__dot--bHht8.LoadingDots__first--FlLVM {
    animation-delay: -80ms;
  }

.LoadingDots__dot--bHht8.LoadingDots__middle--crCme {
    animation-delay: -160ms;
  }

.LoadingDots__dot--bHht8.LoadingDots__last--uShPM {
    margin-right: 0;
  }

@keyframes LoadingDots__loading-dots--xm7S_ {
  0% {
    transform: translateY(0);
  }

  40% {
    transform: translateY(-75%);
  }

  80%,
  100% {
    transform: translateY(0);
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/loadingDots/LoadingDots.pcss"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,kBAAkB;AACpB;;AAEA;EACE,cAAc;EACd,eAAe;EACf,gBAAgB;EAChB,uBAAuB;EACvB,wBAAwB;EACxB,kBAAkB;EAClB,gDAA4B;EAC5B,0BAA0B;EAC1B,sCAAsC;EACtC,mCAAmC;AAarC;;AAXE;IACE,sBAAsB;EACxB;;AAEA;IACE,uBAAuB;EACzB;;AAEA;IACE,eAAe;EACjB;;AAGF;EACE;IACE,wBAAwB;EAC1B;;EAEA;IACE,2BAA2B;EAC7B;;EAEA;;IAEE,wBAAwB;EAC1B;AACF","sourcesContent":[".loading-dots {\n  display: flex;\n  position: relative;\n}\n\n.dot {\n  display: block;\n  width: 0.625rem;\n  height: 0.625rem;\n  margin-right: 0.3125rem;\n  background: var(--black);\n  border-radius: 50%;\n  animation-name: loading-dots;\n  animation-duration: 1200ms;\n  animation-timing-function: ease-in-out;\n  animation-iteration-count: infinite;\n\n  &.first {\n    animation-delay: -80ms;\n  }\n\n  &.middle {\n    animation-delay: -160ms;\n  }\n\n  &.last {\n    margin-right: 0;\n  }\n}\n\n@keyframes loading-dots {\n  0% {\n    transform: translateY(0);\n  }\n\n  40% {\n    transform: translateY(-75%);\n  }\n\n  80%,\n  100% {\n    transform: translateY(0);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loading-dots": `LoadingDots__loading-dots--xm7S_`,
	"loadingDots": `LoadingDots__loading-dots--xm7S_`,
	"dot": `LoadingDots__dot--bHht8`,
	"first": `LoadingDots__first--FlLVM`,
	"middle": `LoadingDots__middle--crCme`,
	"last": `LoadingDots__last--uShPM`
};
export default ___CSS_LOADER_EXPORT___;
