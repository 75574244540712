import NotificationTool from '@/tools/NotificationTool';
import AnalyticsTool from '@/tools/AnalyticsTool';
import { NotificationComponentTools } from '@/types/types';
import OpenStateTool from '@/tools/OpenStateTool';

export default abstract class RenewableNotificationComponent {
  protected renderListener = this.render.bind(this);
  protected readonly notificationTool: NotificationTool;
  protected readonly analyticsTool: AnalyticsTool;
  protected readonly openStateTool: OpenStateTool;
  protected readonly tools: NotificationComponentTools;

  protected constructor(tools: NotificationComponentTools) {
    this.notificationTool = tools.notificationTool;
    this.analyticsTool = tools.analyticsTool;
    this.openStateTool = tools.openStateTool;
    this.tools = tools;
  }

  abstract init(): HTMLElement;

  abstract render(): void;
}
