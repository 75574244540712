import { createElement } from '@/tools/createElement';
import styles from '@/components/counter/Counter.pcss';
import RenewableNotificationComponent from '@/components/RenewableNotificationComponent';
import EventTool from '@/tools/EventTool';
import { NotificationComponentTools } from '@/types/types';

export default class Counter extends RenewableNotificationComponent {
  private readonly counterElement: HTMLSpanElement;
  private readonly counterStyles: string | undefined;

  constructor(tools: NotificationComponentTools, counterStyles?: string) {
    super(tools);
    this.counterElement = createElement<HTMLSpanElement>('span');
    this.counterStyles = counterStyles;
  }

  public init(): HTMLSpanElement {
    this.setCSSClasses();
    this.formatCounterText();

    EventTool.addUnseenCountUpdatedListener(this.renderListener);
    EventTool.addButtonColorToBlackListener(this.buttonColorToBlack.bind(this));
    EventTool.addButtonColorToWhiteListener(this.buttonColorToWhite.bind(this));

    return this.counterElement;
  }

  public render(): void {
    this.setCSSClasses();
    this.formatCounterText();
  }

  private formatCounterText(): void {
    const unseenNotifications = this.notificationTool.unseen;

    if (unseenNotifications <= 0 || !Number.isInteger(unseenNotifications)) {
      this.counterElement.innerHTML = '';
      return;
    }

    if (unseenNotifications > 9) {
      this.counterElement.innerHTML = '9+';
      return;
    }

    this.counterElement.innerHTML = `${unseenNotifications}`;
  }

  private setCSSClasses(): void {
    if (this.notificationTool.unseen <= 0) {
      this.counterElement.classList.remove(...(this.counterElement.classList as unknown as Array<string>));
      return;
    }

    this.counterElement.classList.add(styles.counter);
    if (this.counterStyles) {
      this.counterElement.classList.add(this.counterStyles);
    }
  }

  private buttonColorToBlack(): void {
    this.counterElement.classList.add(styles.blackButton);
  }

  private buttonColorToWhite(): void {
    this.counterElement.classList.remove(styles.blackButton);
  }
}
