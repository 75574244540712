import ApiResource from '@/resources/ApiResource';
import { SSOLoginTokenResponse, SSOPingResponse } from '@/resources/types';

const BASE_URL = process.env.SSO_API_URL;

export default class SsoApiResource {
  private readonly apiResource: ApiResource;
  private readonly target: string;

  constructor(target: string) {
    this.apiResource = new ApiResource();
    this.target = target;
  }

  async ping(): Promise<SSOPingResponse | null> {
    const requestUrl = `${BASE_URL}ping?target=${this.target}`;

    return this.apiResource.getData<SSOPingResponse>(requestUrl, true);
  }

  async getToken(): Promise<string | null> {
    const requestUrl = `${BASE_URL}nstoken?target=${this.target}`;

    return this.apiResource.getData<SSOLoginTokenResponse>(requestUrl, true).then(({ token = null }) => token);
  }
}
