// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TextSection__text-section--BWp1c {
  font-style: italic;
  display: flex;
  justify-content: center;
  min-height: 1.375rem;
}
`, "",{"version":3,"sources":["webpack://./src/components/textSection/TextSection.pcss"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,aAAa;EACb,uBAAuB;EACvB,oBAAoB;AACtB","sourcesContent":[".text-section {\n  font-style: italic;\n  display: flex;\n  justify-content: center;\n  min-height: 1.375rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"text-section": `TextSection__text-section--BWp1c`,
	"textSection": `TextSection__text-section--BWp1c`
};
export default ___CSS_LOADER_EXPORT___;
