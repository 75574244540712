// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.helper__visually-hidden--M6Luj {
  position: absolute !important;
  clip: rect(0.0625rem, 0.0625rem, 0.0625rem, 0.0625rem) !important;
  padding: 0 !important;
  border: 0 !important;
  height: 0.0625rem !important;
  width: 0.0625rem !important;
  overflow: hidden !important;
}
`, "",{"version":3,"sources":["webpack://./src/assets/styles/helper.pcss"],"names":[],"mappings":"AAAA;EACE,6BAA6B;EAC7B,iEAAqD;EACrD,qBAAqB;EACrB,oBAAoB;EACpB,4BAAyB;EACzB,2BAAwB;EACxB,2BAA2B;AAC7B","sourcesContent":[".visually-hidden {\n  position: absolute !important;\n  clip: rect(rem(1), rem(1), rem(1), rem(1)) !important;\n  padding: 0 !important;\n  border: 0 !important;\n  height: rem(1) !important;\n  width: rem(1) !important;\n  overflow: hidden !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"visually-hidden": `helper__visually-hidden--M6Luj`,
	"visuallyHidden": `helper__visually-hidden--M6Luj`
};
export default ___CSS_LOADER_EXPORT___;
