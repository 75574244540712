import { SerializableParameters } from '@/resources/types';

export function serialize(params: SerializableParameters): string {
  const searchParams = new URLSearchParams();

  for (const [key, value] of Object.entries(params)) {
    if (value !== null && value !== undefined) {
      searchParams.append(key, value.toString());
    }
  }

  return searchParams.toString();
}
