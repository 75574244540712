// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.NotificationCenterContent__content--E8I_r {
  max-height: calc(var(--vh, 1vh) * 100 - 9.53125rem);
  overflow: auto;
  overscroll-behavior: contain;
}

  @media (min-width: 48rem) {.NotificationCenterContent__content--E8I_r {
    max-height: 31.25rem
}
}
`, "",{"version":3,"sources":["webpack://./src/components/notificationCenterContent/NotificationCenterContent.pcss"],"names":[],"mappings":"AAAA;EACE,mDAAmD;EACnD,cAAc;EACd,4BAA4B;AAK9B;;EAHE,2BALF;IAMI;AAEJ;AADE","sourcesContent":[".content {\n  max-height: calc(var(--vh, 1vh) * 100 - rem(152.5));\n  overflow: auto;\n  overscroll-behavior: contain;\n\n  @mixin breakpoint {\n    max-height: rem(500);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"content": `NotificationCenterContent__content--E8I_r`
};
export default ___CSS_LOADER_EXPORT___;
