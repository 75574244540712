import EventTool from '@/tools/EventTool';
import styles from '@/components/notificationCenter/NotificationCenter.pcss';
import NotificationCenter from '@/components/notificationCenter/NotificationCenter';
import AnalyticsTool from '@/tools/AnalyticsTool';
import NotificationTool from '@/tools/NotificationTool';

export default class OpenStateTool {
  get open(): boolean {
    return this._open;
  }

  private _open: boolean;
  private readonly bodyClickListener: EventListener;
  private readonly analyticsTool: AnalyticsTool;
  private readonly notificationTool: NotificationTool;
  private center?: NotificationCenter;

  constructor(analyticsTool: AnalyticsTool, notificationTool: NotificationTool) {
    this.analyticsTool = analyticsTool;
    this.notificationTool = notificationTool;
    this._open = false;
    this.bodyClickListener = this.bodyClickListenerFunction.bind(this) as EventListener;
  }

  public connect(center: NotificationCenter): void {
    this.center = center;
  }

  public openCenter(): void {
    if (!this.center || this.open) return;

    this.center.openNotificationCenter();
    window.document.documentElement.classList.add(styles.preventScrollOnMobile);
    document.body.addEventListener('click', this.bodyClickListener, true);
    EventTool.dispatchNotificationClientOpenedEvent();
    this.analyticsTool.pushOpenEvent(this.notificationTool.unseen);
    this._open = true;
  }

  public closeCenter(): void {
    if (!this.center || !this.open) return;

    this.center.closeNotificationCenter();
    window.document.documentElement.classList.remove(styles.preventScrollOnMobile);
    document.body.removeEventListener('click', this.bodyClickListener, true);
    EventTool.dispatchNotificationClientClosedEvent();
    this._open = false;
  }

  /**
   * Event listener for body to close the notification center by clicking outside.
   */
  private bodyClickListenerFunction(e: MouseEvent): void {
    if (!this.center) return;
    let p = e.target as HTMLElement;
    while (p) {
      if (p.id === this.center.id) {
        return;
      }
      if (p.nodeName === 'BODY') {
        break;
      }
      p = p.parentNode as HTMLElement;
    }
    this.closeCenter();
  }
}
