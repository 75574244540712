export function createElement<T extends HTMLElement>(tag: string, classes?: string[]): T {
  const element = document.createElement(tag) as T;

  if (!classes) {
    return element;
  }

  classes.forEach((className: string) => {
    element.classList.add(className);
  });

  return element;
}
