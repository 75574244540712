import ApiResource from '@/resources/ApiResource';
import { MarkNotificationsData } from '@/types/types';
import { serialize } from '@/resources/UrlParameters';
import {
  LatestNotificationApiResponse,
  NotificationApiResponse,
  NotificationParameters,
  NotificationsNotSeenCount,
} from '@/resources/types';
import {
  AxiosError,
  AxiosHeaders,
  AxiosInstance,
  AxiosRequestHeaders,
  AxiosResponse,
  RawAxiosRequestConfig,
} from 'axios';
import TokenProvider from '@/tools/TokenProvider';

const BASE_URL = process.env.OIKOTIE_API_URL;

export default class OikotieApiResource {
  public token: string | null;
  public userKey: string | null;

  private sessionExpired: boolean;
  private readonly apiResource: ApiResource;
  private readonly tokenProvider: TokenProvider;

  constructor(token: string, userKey: string, target: string) {
    this.token = token;
    this.userKey = userKey;
    this.apiResource = new ApiResource();
    this.tokenProvider = new TokenProvider(target);
    this.sessionExpired = false;

    const axios: AxiosInstance = this.apiResource.getInstance();
    axios.interceptors.response.use(
      (response: AxiosResponse) => response,
      async (error: AxiosError) => await this.responseInterceptor(error, axios),
    );
  }

  async getLatestNotifications(): Promise<LatestNotificationApiResponse> {
    const requestUrl = `${BASE_URL}user/notifications/latest`;

    return this.apiResource.getData<LatestNotificationApiResponse>(requestUrl, false, this.getHeaders());
  }

  async setNotificationsSeen(): Promise<void> {
    const requestUrl = `${BASE_URL}user/notifications/set_seen`;

    return this.apiResource.putData<void, null>(requestUrl, null, this.getHeaders());
  }

  async getNotifications(params: NotificationParameters): Promise<NotificationApiResponse> {
    const requestUrl = `${BASE_URL}user/notifications?${serialize(params)}`;

    return this.apiResource.getData<NotificationApiResponse>(requestUrl, false, this.getHeaders());
  }

  async getNotificationsNotSeenCount(countNotSeen: number): Promise<NotificationsNotSeenCount> {
    const requestUrl = `${BASE_URL}user/notifications?countnotseen=${countNotSeen}`;

    return this.apiResource.getData<NotificationsNotSeenCount>(requestUrl, false, this.getHeaders());
  }

  async markNotifications(data: MarkNotificationsData[]): Promise<void> {
    const requestUrl = `${BASE_URL}user/notifications/mark`;

    return this.apiResource.putData<void, MarkNotificationsData[]>(requestUrl, data, this.getHeaders());
  }

  private getHeaders(): AxiosHeaders {
    const headers = new AxiosHeaders();

    if (this.token) {
      headers.set('ot-ns-token', this.token);
    }

    if (this.userKey) {
      headers.set('X-User-Key', this.userKey);
    }

    return headers;
  }

  /**
   * Intercept response that failed with HTTP 401. This can mean that session has expired.
   * Try to renew the token and userKey and try one more time. It that fails the user has most likely logged out.
   * Retry only once
   *
   * @param error
   * @param axios
   * @private
   */
  private async responseInterceptor(error: AxiosError, axios: AxiosInstance) {
    const status = error.response?.status || null;

    if (status === 401 && !this.sessionExpired) {
      this.sessionExpired = true;

      try {
        const { token, userKey } = await this.tokenProvider.load();
        if (!token || !userKey) {
          return Promise.reject(error);
        }
        this.token = token;
        this.userKey = userKey;

        if (error.config) {
          const headers = new AxiosHeaders(error.config.headers);
          error.config.headers = headers.concat(new AxiosHeaders(this.getHeaders())) as AxiosRequestHeaders;
        }

        this.sessionExpired = false;
      } catch (e) {
        return Promise.reject(error);
      }

      return axios.request(error.config as RawAxiosRequestConfig);
    }

    return Promise.reject(error);
  }
}
