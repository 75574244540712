import SsoApiResource from '@/resources/SsoApiResource';
import * as TokenStorage from '@/tools/TokenStorage';
import TokenValidator from '@/tools/TokenValidator';

export default class TokenProvider {
  private readonly tokenValidator: TokenValidator;
  private readonly ssoApi: SsoApiResource;
  private readonly target: string;

  constructor(target: string) {
    this.target = target;
    this.tokenValidator = new TokenValidator();
    this.ssoApi = new SsoApiResource(target);
  }

  /**
   * Load token and user key for Oikotie API session
   */
  async load(): Promise<{ token?: string; userKey?: string }> {
    const userKey = await this.fetchUserKey();

    if (!userKey) {
      return {};
    }

    const token = await this.getToken(userKey);

    if (!token) {
      return {};
    }

    return {
      token,
      userKey,
    };
  }

  private async fetchNewToken(): Promise<string | null> {
    let ssoToken: string | null;
    try {
      ssoToken = (await this.ssoApi.getToken()) || null;
    } catch (error) {
      return null;
    }

    if (await this.tokenValidator.isTokenValid(ssoToken)) {
      return ssoToken;
    }
    return null;
  }

  private async getToken(userKey: string): Promise<string | false> {
    const tokenFromStorage = TokenStorage.get(userKey);

    if (tokenFromStorage && (await this.tokenValidator.isTokenValid(tokenFromStorage))) {
      TokenStorage.remove(userKey);
      return tokenFromStorage;
    }

    const token = await this.fetchNewToken();

    if (!token) {
      return false;
    }

    TokenStorage.save(userKey, token);

    return token;
  }

  /**
   * Will fail if user is not logged in
   */
  private async fetchUserKey(): Promise<string | null> {
    try {
      const result = (await this.ssoApi.ping()) || null;

      if (!result || result.status !== 'ok' || !result.userkey) return null;

      return result.userkey;
    } catch (error) {
      return null;
    }
  }
}
