import axios, { AxiosHeaders, AxiosInstance } from 'axios';

export default class ApiResource {
  private readonly axios: AxiosInstance;

  constructor() {
    this.axios = axios.create();
  }

  getInstance(): AxiosInstance {
    return this.axios;
  }

  async getData<TResponse>(url: string, withCredentials = false, headers?: AxiosHeaders): Promise<TResponse> {
    return this.axios
      .get<TResponse>(url, {
        withCredentials,
        headers: new AxiosHeaders(headers),
      })
      .then(({ data }) => data);
  }

  async putData<TResponse, TData>(url: string, data: TData, headers?: AxiosHeaders): Promise<TResponse> {
    const requestHeaders = new AxiosHeaders(headers);
    requestHeaders.set('Content-Type', 'application/json');

    return await this.axios
      .put<TResponse>(url, data, {
        headers: requestHeaders,
      })
      .then(({ data }) => data);
  }
}
