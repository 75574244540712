import { DateGroup, NotificationComponentTools } from '@/types/types';
import SingleNotification from '@/components/singleNotification/SingleNotification';
import styles from '@/components/notificationListSection/NotificationListSections.pcss';
import RenewableNotificationComponent from '@/components/RenewableNotificationComponent';
import { createElement } from '@/tools/createElement';
import EventTool from '@/tools/EventTool';
import helperStyles from '@/assets/styles/helper.pcss';

export default class NotificationListSection extends RenewableNotificationComponent {
  private readonly dateGroup: DateGroup;
  private readonly titleText: string;
  private readonly renderedNotifications: number[];
  private readonly element: HTMLElement;
  private readonly listElement: HTMLUListElement;
  private readonly titleElement: HTMLHeadElement;
  private readonly titles: Record<DateGroup, string> = {
    today: 'Tänään',
    yesterday: 'Eilen',
    older: 'Vanhemmat',
  };

  constructor(dateGroup: DateGroup, tools: NotificationComponentTools) {
    super(tools);
    this.dateGroup = dateGroup;
    this.titleText = this.titles[dateGroup];
    this.element = createElement('section', [styles.section, styles.empty, styles.init]);
    this.listElement = createElement('ul', [styles.list]);
    this.titleElement = createElement('header', [styles.title]);
    this.renderedNotifications = [];
  }

  public init(): HTMLElement {
    const titleId = NotificationListSection.generateId(this.titleText);
    this.element.setAttribute('aria-labelledby', titleId);

    this.titleElement.id = titleId;
    this.titleElement.innerHTML = this.titleText;
    this.element.appendChild(this.titleElement);
    this.element.appendChild(this.listElement);

    EventTool.addNotificationsFetchedListener(this.renderListener);
    EventTool.addNextPageFetchedListener(this.renderListener);
    EventTool.addNewNotificationsFetchedListener(this.renderListener);

    return this.element;
  }

  public render(): void {
    EventTool.removeNotificationsFetchedListener(this.renderListener);
    this.element.classList.remove(styles.init);

    if (this.notificationTool.allFetched) {
      EventTool.removeNextPageFetchedListener(this.renderListener);
    }

    let notifications = this.notificationTool.getNotificationsByGroup(this.dateGroup);

    if (notifications.length <= 0) {
      this.element.classList.add(styles.empty);
      this.titleElement.classList.add(helperStyles.visuallyHidden);
      this.listElement.innerHTML = '';
      return;
    }

    this.titleElement.classList.remove(helperStyles.visuallyHidden);
    this.element.classList.remove(styles.empty);
    const newest = notifications[0].timestamp;

    notifications = notifications.filter((notification) => !this.renderedNotifications.includes(notification.id));

    notifications.forEach((notificationData) => {
      const listItem = createElement('li', [styles.listItem]);
      const notification = new SingleNotification(notificationData.id, this.tools).render();

      listItem.appendChild(notification);
      if (notificationData.timestamp >= newest) {
        this.listElement.insertBefore(listItem, this.listElement.firstChild);
      } else {
        this.listElement.appendChild(listItem);
      }
      this.renderedNotifications.push(notificationData.id);
    });
  }

  private static generateId(text: string): string {
    const id = text.replace(/([^A-Za-z\d[\]{}_.:-])\s?/g, '').toLowerCase();
    return `${id}-label`;
  }
}
