import { NotificationComponentTools, NotificationData } from '@/types/types';
import styles from '@/components/singleNotification/SingleNotification.pcss';
import NotificationComponent from '@/components/NotificationComponent';
import { createElement } from '@/tools/createElement';
import DateTool from '@/tools/DateTool';
import EventTool from '@/tools/EventTool';

export default class SingleNotification extends NotificationComponent {
  private readonly notificationData: NotificationData;
  private readonly notification: HTMLAnchorElement;
  private readonly textElement: HTMLSpanElement;
  private readonly timeElement: HTMLSpanElement;
  private intervalId?: ReturnType<typeof setTimeout>;

  constructor(id: number, tools: NotificationComponentTools) {
    super(tools);
    const notificationData = this.notificationTool.getNotificationById(id);
    if (!notificationData) {
      throw `There is no data for id ${id}`;
    }

    this.notificationData = notificationData;
    this.notification = createElement<HTMLAnchorElement>('a', [
      styles.singleNotification,
      styles[this.notificationData.vertical],
    ]);

    this.textElement = createElement('span', [styles.title]);
    this.notification.appendChild(this.textElement);

    this.timeElement = createElement('span', [styles.time]);
    this.notification.appendChild(this.timeElement);
  }

  public render(): HTMLAnchorElement {
    this.notification.setAttribute('href', encodeURI(this.notificationData.target));

    if (this.notificationData.isNew) {
      this.notification.classList.add(styles.isNew);
    }

    this.textElement.textContent = this.notificationData.title;

    this.timeElement.textContent = DateTool.generateTime(this.notificationData.timestamp);

    if (this.openStateTool.open) {
      this.updateTimestamp();
    }

    this.addEventListeners();
    EventTool.addNotificationClientOpenedListener(this.updateTimestamp.bind(this));
    EventTool.addNotificationClientClosedListener(() => {
      if (this.intervalId) clearInterval(this.intervalId);
    });

    return this.notification;
  }

  private addEventListeners(): void {
    this.notification.addEventListener('click', (event) => {
      event.preventDefault();

      const position = this.notificationTool.getNotificationPosition(this.notificationData.id) + 1;
      this.analyticsTool.pushClickEvent(position);
      this.markNotificationAsRead().then(() => {
        window.location.assign(this.notificationData.target);
      });
    });
  }

  private async markNotificationAsRead(): Promise<void> {
    this.notificationData.isNew = false;
    this.notification.classList.remove(styles.isNew);
    return this.notificationTool.markNotificationAsRead(this.notificationData.id);
  }

  private updateTimestamp(): void {
    this.timeElement.textContent = DateTool.generateTime(this.notificationData.timestamp);

    if (!DateTool.newerThanHourAgo(this.notificationData.timestamp)) {
      if (this.intervalId) clearInterval(this.intervalId);

      return;
    }

    this.intervalId = setInterval(() => {
      const oldTime = this.timeElement.textContent;
      const currentTime = DateTool.generateTime(this.notificationData.timestamp);

      if (oldTime === currentTime) return;

      this.timeElement.textContent = currentTime;
    }, 1000 * 60);
  }
}
