// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#oikotie-notification-center {
  --white: #fff;
  --black: #202020;
  --light-gray: #f4f4f4;
  --blue: #00a8eb;
  --green: #51b325;
  --purple: #c33c9d;
  --red: #e00000;

  --super-small-font: 0.75rem;
  --small-font: 0.875rem;
  --medium-font: 1rem;
  --large-font: 1.25rem;

  --icon-size: 1rem;
  --icon-size-large: 1.5rem;

  --font-weight-normal: 400;
  --font-weight-bold: 700;
  --font-family: 'Roboto', Helvetica, Arial, sans-serif;

  --text-color: #202020;
  --text-color-light: #808080;

  --transition: 150ms;

  --drawer-animation: cubic-bezier(0.82, 0.085, 0.395, 0.895);
}
`, "",{"version":3,"sources":["webpack://./src/assets/styles/variables.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,gBAAgB;EAChB,qBAAqB;EACrB,eAAe;EACf,gBAAgB;EAChB,iBAAiB;EACjB,cAAc;;EAEd,2BAA2B;EAC3B,sBAAsB;EACtB,mBAAmB;EACnB,qBAAqB;;EAErB,iBAAiB;EACjB,yBAAyB;;EAEzB,yBAAyB;EACzB,uBAAuB;EACvB,qDAAqD;;EAErD,qBAAqB;EACrB,2BAA2B;;EAE3B,mBAAmB;;EAEnB,2DAA2D;AAC7D","sourcesContent":[":global #oikotie-notification-center {\n  --white: #fff;\n  --black: #202020;\n  --light-gray: #f4f4f4;\n  --blue: #00a8eb;\n  --green: #51b325;\n  --purple: #c33c9d;\n  --red: #e00000;\n\n  --super-small-font: 0.75rem;\n  --small-font: 0.875rem;\n  --medium-font: 1rem;\n  --large-font: 1.25rem;\n\n  --icon-size: 1rem;\n  --icon-size-large: 1.5rem;\n\n  --font-weight-normal: 400;\n  --font-weight-bold: 700;\n  --font-family: 'Roboto', Helvetica, Arial, sans-serif;\n\n  --text-color: #202020;\n  --text-color-light: #808080;\n\n  --transition: 150ms;\n\n  --drawer-animation: cubic-bezier(0.82, 0.085, 0.395, 0.895);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
