import NotificationListSection from '@/components/notificationListSection/NotificationListSection';
import styles from '@/components/notificationCenterContent/NotificationCenterContent.pcss';
import RenewableNotificationComponent from '@/components/RenewableNotificationComponent';
import { createElement } from '@/tools/createElement';
import TextSection from '@/components/textSection/TextSection';
import LoadingDots from '@/components/loadingDots/LoadingDots';
import EventTool from '@/tools/EventTool';
import { DateGroup, NotificationComponentTools } from '@/types/types';
import { setViewportHeightAdjuster } from '@/tools/viewport';

export default class NotificationCenterContent extends RenewableNotificationComponent {
  private readonly todaySection: NotificationListSection;
  private readonly yesterdaySection: NotificationListSection;
  private readonly olderSection: NotificationListSection;
  private readonly textSection: TextSection;
  private readonly content: HTMLDivElement;
  private readonly observerOptions: IntersectionObserverInit;
  private readonly observerCallback: IntersectionObserverCallback;
  private observer: IntersectionObserver | undefined;
  private textSectionContent: HTMLElement | undefined;

  constructor(tools: NotificationComponentTools) {
    super(tools);
    this.content = createElement<HTMLDivElement>('div', [styles.content]);
    this.todaySection = new NotificationListSection(DateGroup.today, tools);
    this.yesterdaySection = new NotificationListSection(DateGroup.yesterday, tools);
    this.olderSection = new NotificationListSection(DateGroup.older, tools);
    this.textSection = new TextSection();

    this.observerOptions = {
      root: this.content.parentElement,
      rootMargin: '0px',
      threshold: 0.9, // When 90% of the observed element is visible
    };
    this.observerCallback = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          this.onScrolledToBottom();
        }
      });
    };
  }

  public init(): HTMLDivElement {
    setViewportHeightAdjuster();

    this.content.appendChild(this.todaySection.init());
    this.content.appendChild(this.yesterdaySection.init());
    this.content.appendChild(this.olderSection.init());
    this.textSectionContent = this.textSection.render();
    this.content.appendChild(this.textSectionContent);

    this.textSection.updateHtml(LoadingDots.render());

    EventTool.addNotificationsFetchedListener(this.renderListener);
    EventTool.addNextPageFetchedListener(this.renderListener);

    this.observer = new IntersectionObserver(this.observerCallback, this.observerOptions);

    return this.content;
  }

  public render(): void {
    EventTool.removeNotificationsFetchedListener(this.renderListener);

    if (this.notificationTool.error) {
      this.observer?.disconnect();
      this.textSection.updateHtml('Ilmoitusten haku ei onnistunut');
      return;
    }

    if (this.notificationTool.notifications.length <= 0) {
      this.observer?.disconnect();
      this.textSection.updateHtml('Ei ilmoituksia');
      return;
    }

    if (this.notificationTool.allFetched) {
      EventTool.removeNextPageFetchedListener(this.renderListener);
      this.observer?.disconnect();
      this.textSection.updateHtml('Ei vanhempia ilmoituksia');
      return;
    }

    this.textSection.updateHtml();
    // Observe the last element, ie user scrolled to bottom
    this.observer?.observe(this.textSectionContent as Element);
  }

  private onScrolledToBottom(): void {
    this.textSection.updateHtml(LoadingDots.render());
    this.notificationTool.fetchNextPage().then();
  }
}
