// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.svg-button__svg-button--bYyhn {
  position: relative;
  border: none;
  background: none;
  cursor: pointer;
  width: auto;
  padding: 0;
  display: block;
}

  .svg-button__svg-button--bYyhn:hover {
    background: initial;
  }

  .svg-button__svg-button--bYyhn:hover:after {
    content: '';
    position: absolute;
    background-color: rgba(0, 0, 0, 0.15);
    transition: background-color var(--transition);
    border-radius: 62.5rem;
    font-size: var(--icon-size-large);
    height: 1.8em;
    width: 1.8em;
    outline: 0;
    text-decoration: none;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
  }

  .svg-button__svg-button--bYyhn svg {
    display: block;
  }
`, "",{"version":3,"sources":["webpack://./src/assets/styles/svg-button.pcss"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,YAAY;EACZ,gBAAgB;EAChB,eAAe;EACf,WAAW;EACX,UAAU;EACV,cAAc;AAyBhB;;EAvBE;IACE,mBAAmB;EACrB;;EAEA;IACE,WAAW;IACX,kBAAkB;IAClB,qCAAqC;IACrC,8CAA8C;IAC9C,sBAAsB;IACtB,iCAAiC;IACjC,aAAa;IACb,YAAY;IACZ,UAAU;IACV,qBAAqB;IACrB,QAAQ;IACR,SAAS;IACT,qCAAqC;EACvC;;EAEA;IACE,cAAc;EAChB","sourcesContent":[".svg-button {\n  position: relative;\n  border: none;\n  background: none;\n  cursor: pointer;\n  width: auto;\n  padding: 0;\n  display: block;\n\n  &:hover {\n    background: initial;\n  }\n\n  &:hover:after {\n    content: '';\n    position: absolute;\n    background-color: rgba(0, 0, 0, 0.15);\n    transition: background-color var(--transition);\n    border-radius: 62.5rem;\n    font-size: var(--icon-size-large);\n    height: 1.8em;\n    width: 1.8em;\n    outline: 0;\n    text-decoration: none;\n    top: 50%;\n    left: 50%;\n    transform: translate3d(-50%, -50%, 0);\n  }\n\n  svg {\n    display: block;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"svg-button": `svg-button__svg-button--bYyhn`,
	"svgButton": `svg-button__svg-button--bYyhn`
};
export default ___CSS_LOADER_EXPORT___;
