// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CloseButton__close-button--aOurw svg {
    fill: var(--brand-color, #202020);
  }
  .CloseButton__close-button--aOurw:hover svg, .CloseButton__close-button--aOurw:focus svg, .CloseButton__close-button--aOurw:active svg {
      fill: var(--brand-color, #202020);
    }
`, "",{"version":3,"sources":["webpack://./src/components/closeButton/CloseButton.pcss"],"names":[],"mappings":"AACE;IACE,iCAAiC;EACnC;EAKE;MACE,iCAAiC;IACnC","sourcesContent":[".close-button {\n  svg {\n    fill: var(--brand-color, #202020);\n  }\n\n  &:hover,\n  &:focus,\n  &:active {\n    svg {\n      fill: var(--brand-color, #202020);\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"close-button": `CloseButton__close-button--aOurw`,
	"closeButton": `CloseButton__close-button--aOurw`
};
export default ___CSS_LOADER_EXPORT___;
