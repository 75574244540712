import { createElement } from '@/tools/createElement';
import styles from '@/components/textSection/TextSection.pcss';

export default class TextSection {
  private readonly element: HTMLElement;

  constructor() {
    this.element = createElement('section', [styles.textSection]);
  }

  public render(): HTMLElement {
    return this.element;
  }

  public updateHtml(content: string | HTMLElement = ''): void {
    while (this.element.firstChild) {
      this.element.removeChild(this.element.firstChild);
    }

    if (!content) {
      return;
    }

    if (typeof content === 'string') {
      const span = document.createElement('span');
      span.innerText = content;
      this.element.appendChild(span);
      return;
    }

    this.element.appendChild(content);
  }
}
