import styles from '@/components/notificationCenter/NotificationCenter.pcss';
import NotificationCenterHeader from '@/components/notificationCenterHeader/NotificationCenterHeader';
import NotificationCenterContent from '@/components/notificationCenterContent/NotificationCenterContent';
import NotificationComponent from '@/components/NotificationComponent';
import { createElement } from '@/tools/createElement';
import { NotificationComponentTools } from '@/types/types';
import Divider from '@/components/divider/Divider';

export default class NotificationCenter extends NotificationComponent {
  public readonly id = 'notification-center';
  private readonly header: NotificationCenterHeader;
  private readonly divider: Divider;
  private readonly content: NotificationCenterContent;
  private readonly element: HTMLDivElement;
  private readonly contentElement: HTMLDivElement;

  constructor(tools: NotificationComponentTools) {
    super(tools);
    this.header = new NotificationCenterHeader(tools);
    this.divider = new Divider();
    this.content = new NotificationCenterContent(tools);

    this.element = createElement<HTMLDivElement>('div', [styles.notificationCenterWrapper, styles.closed]);
    this.element.id = 'notification-center-wrapper';

    this.contentElement = createElement('div', [styles.notificationCenter]);
    this.contentElement.id = this.id;
    this.element.appendChild(this.contentElement);

    this.openStateTool.connect(this);
  }

  /**
   * Renders the notification center.
   */
  public render(): HTMLDivElement {
    this.contentElement.appendChild(this.header.render());
    this.contentElement.appendChild(this.divider.render());
    this.contentElement.appendChild(this.content.init());

    return this.element;
  }

  /**
   * Closes the notification center.
   */
  public closeNotificationCenter(): void {
    this.element.classList.add(styles.closed);
  }

  /**
   * Opens the notification center.
   */
  public openNotificationCenter(): void {
    this.element.classList.remove(styles.closed);
  }
}
