// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.NotificationCenterHeader__header--iXJdb {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.NotificationCenterHeader__title-wrapper--nqkMk {
  display: flex;
  align-items: center;
}

.NotificationCenterHeader__icon--WeHK_ {
  margin-right: 0.625rem;
  line-height: 0;
}

.NotificationCenterHeader__title--XtpcR {
  font-size: var(--large-font);
  font-weight: var(--font-weight-bold);
}
`, "",{"version":3,"sources":["webpack://./src/components/notificationCenterHeader/NotificationCenterHeader.pcss"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,sBAAqB;EACrB,cAAc;AAChB;;AAEA;EACE,4BAA4B;EAC5B,oCAAoC;AACtC","sourcesContent":[".header {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n}\n\n.title-wrapper {\n  display: flex;\n  align-items: center;\n}\n\n.icon {\n  margin-right: rem(10);\n  line-height: 0;\n}\n\n.title {\n  font-size: var(--large-font);\n  font-weight: var(--font-weight-bold);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": `NotificationCenterHeader__header--iXJdb`,
	"title-wrapper": `NotificationCenterHeader__title-wrapper--nqkMk`,
	"titleWrapper": `NotificationCenterHeader__title-wrapper--nqkMk`,
	"icon": `NotificationCenterHeader__icon--WeHK_`,
	"title": `NotificationCenterHeader__title--XtpcR`
};
export default ___CSS_LOADER_EXPORT___;
