// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.NotificationClient__container--n8doQ {
  font-family: var(--font-family);
  font-size: var(--medium-font);
  font-style: normal;
  font-weight: var(--font-weight-normal);
  text-transform: none;
  line-height: 1.375;
  color: var(--black);
}
`, "",{"version":3,"sources":["webpack://./src/components/notificationClient/NotificationClient.pcss"],"names":[],"mappings":"AAAA;EACE,+BAA+B;EAC/B,6BAA6B;EAC7B,kBAAkB;EAClB,sCAAsC;EACtC,oBAAoB;EACpB,kBAAkB;EAClB,mBAAmB;AACrB","sourcesContent":[".container {\n  font-family: var(--font-family);\n  font-size: var(--medium-font);\n  font-style: normal;\n  font-weight: var(--font-weight-normal);\n  text-transform: none;\n  line-height: 1.375;\n  color: var(--black);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `NotificationClient__container--n8doQ`
};
export default ___CSS_LOADER_EXPORT___;
