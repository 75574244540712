// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Divider__divider--IhP8j {
  height: 0.3125rem;
  background-color: var(--brand-color, #202020);
  border-width: 0;
  margin: 1.875rem 0;
}
`, "",{"version":3,"sources":["webpack://./src/components/divider/Divider.pcss"],"names":[],"mappings":"AAAA;EACE,iBAAc;EACd,6CAA6C;EAC7C,eAAe;EACf,kBAAiB;AACnB","sourcesContent":[".divider {\n  height: rem(5);\n  background-color: var(--brand-color, #202020);\n  border-width: 0;\n  margin: rem(30) 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"divider": `Divider__divider--IhP8j`
};
export default ___CSS_LOADER_EXPORT___;
