import buttonStyles from '@/assets/styles/svg-button.pcss';
import styles from '@/components/closeButton/CloseButton.pcss';
import { closeIcon } from '@/assets/icons/icons';
import helperStyles from '@/assets/styles/helper.pcss';
import { createElement } from '@/tools/createElement';
import NotificationComponent from '@/components/NotificationComponent';
import { NotificationComponentTools } from '@/types/types';

export default class CloseButton extends NotificationComponent {
  private readonly button: HTMLButtonElement;
  private readonly helperText = 'Sulje ilmoitukset';

  constructor(tools: NotificationComponentTools) {
    super(tools);
    this.button = createElement<HTMLButtonElement>('button', [styles.closeButton, buttonStyles.svgButton]);
  }

  public render(): HTMLButtonElement {
    this.button.setAttribute('type', 'button');
    this.button.setAttribute('title', this.helperText);

    this.button.innerHTML = closeIcon();

    const text = createElement('span', [helperStyles.visuallyHidden]);
    text.innerHTML = this.helperText;
    this.button.appendChild(text);

    this.addEventListeners();

    return this.button;
  }

  private addEventListeners(): void {
    this.button.addEventListener('click', () => {
      this.clickButton();
    });
  }

  private clickButton(): void {
    this.openStateTool.closeCenter();
  }
}
