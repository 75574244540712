import { VerticalAnalyticsName } from '@/types/types';

export function setBrandColorVariable(vertical: VerticalAnalyticsName): void {
  const container = document.getElementById('oikotie-notification-center');

  if (!container) return;

  if (container.style.getPropertyValue('--brand-color')) {
    return;
  }

  let brandColor = 'black';
  switch (vertical) {
    case 'otas':
      brandColor = 'blue';
      break;
    case 'otty':
      brandColor = 'green';
      break;
    case 'otto':
      brandColor = 'purple';
      break;
  }

  container.style.setProperty('--brand-color', `var(--${brandColor})`);
}
