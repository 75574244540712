import styles from '@/components/divider/Divider.pcss';
import { createElement } from '@/tools/createElement';

export default class Divider {
  private readonly divider: HTMLHRElement;

  constructor() {
    this.divider = createElement<HTMLHRElement>('hr', [styles.divider]);
  }

  public render(): HTMLHRElement {
    return this.divider;
  }
}
