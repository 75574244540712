import { DateGroup, MarkNotificationsData, NotificationData, NotificationFeed, Vertical } from '@/types/types';
import DateTool from '@/tools/DateTool';
import EventTool from '@/tools/EventTool';
import OikotieApiResource from '@/resources/OikotieApiResource';
import { NotificationParameters } from '@/resources/types';

/**
 * Time in seconds
 */
const REFRESH_TIME = 30;

/**
 * Class for fetching the notifications.
 */
export default class NotificationTool {
  get unseen(): number {
    return this._unseen;
  }

  get allFetched(): boolean {
    return this._allFetched;
  }

  get notifications(): NotificationData[] {
    return this._notifications;
  }

  public get error(): boolean {
    return this._error;
  }

  private oikotieApiResource: OikotieApiResource;
  private readonly refreshTime: number;
  private _notifications: NotificationData[] = [];
  private _error = false;
  private _allFetched = false;
  private _unseen = 0;
  private page = 1;
  private pageSize = 10;
  private fetching = false;
  private latestTimestamp = 0;

  constructor(token: string, userKey: string, target: string, refreshTime?: number) {
    this.oikotieApiResource = new OikotieApiResource(token, userKey, target);
    this.refreshTime = refreshTime ?? REFRESH_TIME;
  }

  /**
   * Init function; fetches and sets notifications and unseen number &
   * if there is no errors, starts the check interval.
   */
  public async init(): Promise<void> {
    const promises = [
      this.fetchNotifications().then(() => {
        EventTool.dispatchNotificationsFetchedListener();
      }),
      this.updateUnseenCount().then(() => {
        EventTool.addNotificationClientClosedListener(this.markNotificationsAsSeen.bind(this));
      }),
    ];

    await Promise.all(promises);

    if (!this.error) {
      this.notificationCheckInterval().then();
    }
  }

  /**
   * Requires the tool fetch more notifications.
   */
  public async fetchNextPage(): Promise<void> {
    if (this.fetching || this.allFetched) return;

    this.fetching = true;
    const promises: Promise<void>[] = [
      this.fetchNotifications(),
      new Promise((resolve) => {
        setTimeout(resolve, 1200, undefined);
      }),
    ];

    await Promise.all(promises);
    this.fetching = false;
    EventTool.dispatchNextPageFetchedListener();
  }

  /**
   * Marks all given notifications as seen or read.
   *
   * @param {number} notificationId
   */
  public async markNotificationAsRead(notificationId: number): Promise<void> {
    const notificationsData: MarkNotificationsData[] = [
      {
        id: notificationId,
        seen: true,
        read: true,
      },
    ];

    return this.oikotieApiResource.markNotifications(notificationsData);
  }

  /**
   * Gets notifications by given date group.
   * @param group
   */
  public getNotificationsByGroup(group: DateGroup): NotificationData[] {
    if (group === DateGroup.today) {
      return this.notifications.filter((notification) => DateTool.isToday(notification.timestamp));
    }

    if (group === DateGroup.yesterday) {
      return this.notifications.filter((notification) => DateTool.isYesterday(notification.timestamp));
    }

    return this.notifications.filter((notification) => DateTool.isOlderThanYesterday(notification.timestamp));
  }

  /**
   * Gets notification by id.
   */
  public getNotificationById(id: number): NotificationData | undefined {
    return this.notifications.find((notification) => notification.id === id);
  }

  /**
   * Gets notification position by id.
   */
  public getNotificationPosition(id: number): number {
    return this.notifications.findIndex((notification) => notification.id === id);
  }

  /**
   * Fetches the latest notification timestamp & compares it to the local timestamp:
   * if fetched one is newer, starts fetching new notifications & updates the unseen number.
   * Sets interval so this check is done every refreshTime seconds.
   */
  private async notificationCheckInterval(): Promise<void> {
    try {
      const latestNotifications = await this.oikotieApiResource.getLatestNotifications();
      const newTimestamp = latestNotifications.ts;

      if (newTimestamp > this.latestTimestamp) {
        await this.updateNotificationsAndCounter();
      }

      await new Promise((resolve) => {
        setTimeout(resolve, 1000 * this.refreshTime);
      });

      if (!this.error) {
        await this.notificationCheckInterval();
      }
    } catch (e) {
      this._error = true;
    }
  }

  /**
   * Fetches notifications after the last timestamp & updates unseen count.
   */
  private async updateNotificationsAndCounter(): Promise<void> {
    const promises = [
      this.fetchNotifications(this.latestTimestamp).then(() => {
        EventTool.dispatchNewNotificationsFetchedListener();
      }),
      await this.updateUnseenCount(),
    ];

    await Promise.all(promises);
  }

  /**
   * Marks all fetched notifications as seen.
   */
  private async markNotificationsAsSeen(): Promise<void> {
    if (this.unseen <= 0) return;

    this._unseen = 0;

    EventTool.dispatchUnseenCountUpdatedListener();
    await this.oikotieApiResource.setNotificationsSeen();
  }

  /**
   * Gets the unseen count from api & updates the local one.
   */
  private async updateUnseenCount(): Promise<void> {
    try {
      const unseenResponse = await this.oikotieApiResource.getNotificationsNotSeenCount(1);
      const unseen = unseenResponse[0].not_seen;

      if (this.unseen === unseen) return;

      this._unseen = unseen;
    } catch (e) {
      this._unseen = 0;
    }

    EventTool.dispatchUnseenCountUpdatedListener();
  }

  /**
   * Fetches notifications from api.
   */
  private async fetchNotifications(afterTimestamp = 0): Promise<void> {
    const page = afterTimestamp ? 1 : this.page;
    const searchParameters: NotificationParameters = {
      types: 31,
      pageSize: this.pageSize,
      page,
      meta: 1,
      after: afterTimestamp,
    };

    try {
      this.page++;
      const notificationsApiResponse = await this.oikotieApiResource.getNotifications(searchParameters);

      const fetchedNotifications = notificationsApiResponse.ndata;

      if (
        afterTimestamp <= 0 &&
        (notificationsApiResponse.num_found === this.pageSize || notificationsApiResponse.returned < this.pageSize)
      ) {
        this._allFetched = true;
      }

      const convertedNotifications = NotificationTool.convertFetchedNotifications(fetchedNotifications);

      this.updateNotifications(
        convertedNotifications,
        afterTimestamp > 0 && notificationsApiResponse.num_found >= this.pageSize,
      );

      this.latestTimestamp = this.notifications[0]?.timestamp ?? 0;
    } catch (error) {
      this._error = true;
    }
  }

  /**
   * Update or replace notifications.
   * @param newNotifications
   * @param replace
   */
  private updateNotifications(newNotifications: NotificationData[], replace = false): void {
    if (newNotifications.length <= 0) return;

    if (replace) {
      this._notifications = newNotifications;
      return;
    }

    if (this.latestTimestamp >= newNotifications[0].timestamp) {
      this._notifications = [...this.notifications, ...newNotifications];
      return;
    }

    this._notifications = [...newNotifications, ...this.notifications];
  }

  /**
   * Converts fetched notification to notificationData type.
   */
  private static convertFetchedNotifications(fetchedNotifications: NotificationFeed[]): NotificationData[] {
    if (!fetchedNotifications) return [];

    return fetchedNotifications.map((data) => ({
      id: data.id,
      title: data.notification_title,
      target: data.notification_url,
      vertical: data.notification_vertical || Vertical.ot,
      timestamp: data.timestamp,
      isNew: data.is_new,
    }));
  }
}
