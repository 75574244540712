// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Counter__counter--mcGPS {
  font-size: var(--super-small-font);
  font-weight: var(--font-weight-bold);
  color: var(--white);
  background: var(--red);
  padding: 0 0.4375rem;
  border-radius: 0.9375rem;
  border: 0.125rem var(--black) solid;
  line-height: 1.0625rem;
}

  .Counter__counter--mcGPS.Counter__black-button--TBu5Y {
    border-color: var(--white);
  }
`, "",{"version":3,"sources":["webpack://./src/components/counter/Counter.pcss"],"names":[],"mappings":"AAAA;EACE,kCAAkC;EAClC,oCAAoC;EACpC,mBAAmB;EACnB,sBAAsB;EACtB,oBAAiB;EACjB,wBAAsB;EACtB,mCAAiC;EACjC,sBAAoB;AAKtB;;EAHE;IACE,0BAA0B;EAC5B","sourcesContent":[".counter {\n  font-size: var(--super-small-font);\n  font-weight: var(--font-weight-bold);\n  color: var(--white);\n  background: var(--red);\n  padding: 0 rem(7);\n  border-radius: rem(15);\n  border: rem(2) var(--black) solid;\n  line-height: rem(17);\n\n  &.black-button {\n    border-color: var(--white);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"counter": `Counter__counter--mcGPS`,
	"black-button": `Counter__black-button--TBu5Y`,
	"blackButton": `Counter__black-button--TBu5Y`
};
export default ___CSS_LOADER_EXPORT___;
