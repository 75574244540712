// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SingleNotification__single-notification--HvoYO {
  display: block;
  padding-left: 0.9375rem;
  font-size: var(--small-font);
  color: var(--text-color);
  text-decoration: none;
  position: relative;
  background: var(--white);
  transition: background-color var(--transition);
}

  .SingleNotification__single-notification--HvoYO:hover,
  .SingleNotification__single-notification--HvoYO:focus,
  .SingleNotification__single-notification--HvoYO:active {
    background: var(--light-gray);
  }

  .SingleNotification__single-notification--HvoYO.SingleNotification__apartment--bc__b {
    box-shadow: inset 0.125rem 0 var(--blue);
  }

  .SingleNotification__single-notification--HvoYO.SingleNotification__recruitment--ta2Q_ {
    box-shadow: inset 0.125rem 0 0 var(--green);
  }

  .SingleNotification__single-notification--HvoYO.SingleNotification__oikotie--EdOHd {
    box-shadow: inset 0.125rem 0 0 var(--black);
  }

.SingleNotification__title--cZNiH {
  display: block;
  margin-bottom: 0.3125rem;
  font-weight: var(--font-weight-normal);
}

.SingleNotification__is-new--spee0 .SingleNotification__title--cZNiH {
    font-weight: var(--font-weight-bold);
  }

.SingleNotification__time--U004h {
  display: block;
  font-size: var(--super-small-font);
  font-weight: var(--font-weight-bold);
  color: var(--text-color-light);
}
`, "",{"version":3,"sources":["webpack://./src/components/singleNotification/SingleNotification.pcss"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,uBAAqB;EACrB,4BAA4B;EAC5B,wBAAwB;EACxB,qBAAqB;EACrB,kBAAkB;EAClB,wBAAwB;EACxB,8CAA8C;AAmBhD;;EAjBE;;;IAGE,6BAA6B;EAC/B;;EAEA;IACE,wCAAwC;EAC1C;;EAEA;IACE,2CAA2C;EAC7C;;EAEA;IACE,2CAA2C;EAC7C;;AAGF;EACE,cAAc;EACd,wBAAqB;EACrB,sCAAsC;AAKxC;;AAHE;IACE,oCAAoC;EACtC;;AAGF;EACE,cAAc;EACd,kCAAkC;EAClC,oCAAoC;EACpC,8BAA8B;AAChC","sourcesContent":[".single-notification {\n  display: block;\n  padding-left: rem(15);\n  font-size: var(--small-font);\n  color: var(--text-color);\n  text-decoration: none;\n  position: relative;\n  background: var(--white);\n  transition: background-color var(--transition);\n\n  &:hover,\n  &:focus,\n  &:active {\n    background: var(--light-gray);\n  }\n\n  &.apartment {\n    box-shadow: inset 0.125rem 0 var(--blue);\n  }\n\n  &.recruitment {\n    box-shadow: inset 0.125rem 0 0 var(--green);\n  }\n\n  &.oikotie {\n    box-shadow: inset 0.125rem 0 0 var(--black);\n  }\n}\n\n.title {\n  display: block;\n  margin-bottom: rem(5);\n  font-weight: var(--font-weight-normal);\n\n  .is-new & {\n    font-weight: var(--font-weight-bold);\n  }\n}\n\n.time {\n  display: block;\n  font-size: var(--super-small-font);\n  font-weight: var(--font-weight-bold);\n  color: var(--text-color-light);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"single-notification": `SingleNotification__single-notification--HvoYO`,
	"singleNotification": `SingleNotification__single-notification--HvoYO`,
	"apartment": `SingleNotification__apartment--bc__b`,
	"recruitment": `SingleNotification__recruitment--ta2Q_`,
	"oikotie": `SingleNotification__oikotie--EdOHd`,
	"title": `SingleNotification__title--cZNiH`,
	"is-new": `SingleNotification__is-new--spee0`,
	"isNew": `SingleNotification__is-new--spee0`,
	"time": `SingleNotification__time--U004h`
};
export default ___CSS_LOADER_EXPORT___;
