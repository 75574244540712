const TOKEN_STORAGE_KEY = 'oikotie_token';

export function get(userKey: string): string | null {
  return localStorage.getItem(`${TOKEN_STORAGE_KEY}${userKey}`);
}

export function save(userKey: string, token: string): void {
  localStorage.setItem(`${TOKEN_STORAGE_KEY}${userKey}`, token);
}

export function remove(userKey: string): void {
  return localStorage.removeItem(`${TOKEN_STORAGE_KEY}${userKey}`);
}
