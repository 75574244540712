import NotificationClient from '@/components/notificationClient/NotificationClient';
import NotificationTool from '@/tools/NotificationTool';
import TokenProvider from '@/tools/TokenProvider';
import { InitOptions } from '@/types/types';
import AnalyticsTool from '@/tools/AnalyticsTool';
import OpenStateTool from '@/tools/OpenStateTool';
import '@/assets/styles/variables.css';
import { setBrandColorVariable } from '@/tools/brandColor';

let client: NotificationClient | null;

async function initNotificationCenter(options: InitOptions): Promise<void> {
  const wrapperElement = document.getElementById('oikotie-notification-center');
  if (!wrapperElement) {
    return;
  }

  const tokenProvider = new TokenProvider(options.target);
  const { token, userKey } = await tokenProvider.load();

  if (!token || !userKey) {
    client = null;
    return;
  }

  const notificationTool = new NotificationTool(token, userKey, options.target, options.refreshTime);
  const analyticsTool = new AnalyticsTool(options.vertical);
  const openStateTool = new OpenStateTool(analyticsTool, notificationTool);

  client = new NotificationClient({
    notificationTool,
    analyticsTool,
    openStateTool,
  });

  setBrandColorVariable(options.vertical);

  wrapperElement.appendChild(client.render());

  await notificationTool.init();
}

function closeNotificationCenter(): void {
  if (!client) {
    return;
  }

  client.closeNotificationCenter();
}

function changedNotificationButtonColorToBlack(): void {
  if (!client) {
    return;
  }

  client.buttonColorChangedToBlack();
}

function changedNotificationButtonColorToWhite(): void {
  if (!client) {
    return;
  }

  client.buttonColorChangedToWhite();
}

export {
  initNotificationCenter,
  closeNotificationCenter,
  changedNotificationButtonColorToBlack,
  changedNotificationButtonColorToWhite,
};
