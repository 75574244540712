// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.NotificationCenter__notification-center-wrapper--wgFVa {
  position: fixed;
  top: 0;
  left: 0;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  -webkit-overflow-scrolling: touch;
  height: 100vh;
  width: 100vw;
  opacity: 1;
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.5);
  color: var(--text-color);
  text-align: left;
  overflow: hidden;
}

  @media (min-width: 48rem) {.NotificationCenter__notification-center-wrapper--wgFVa {
    box-shadow: 0 0 6.25rem 0 rgba(0, 0, 0, 0.15);
    height: auto;
    max-height: 100vh;
    width: 23.75rem;
    position: absolute;
    background-color: transparent;
    right: 0;
    left: auto;
    transition:
      transform 0.2s var(--drawer-animation),
      opacity 0.2s var(--drawer-animation),
      margin-top var(--transition) linear,
      visibility 0.2s var(--drawer-animation);
    transform: translate3d(0, 0, 0);
    overflow: hidden;
}
}

  .NotificationCenter__notification-center-wrapper--wgFVa.NotificationCenter__closed--CO_2w {
    visibility: hidden;
    display: block;
    opacity: 0;
    transform: translate3d(0, -100%, 0);
  }

.NotificationCenter__notification-center--hNTCx {
  position: relative;
  background: var(--white);
  padding: 1.875rem;
}

.NotificationCenter__prevent-scroll-on-mobile--JGJLR {
  overflow: hidden;
  touch-action: none;
}

@media (min-width: 48rem) {

.NotificationCenter__prevent-scroll-on-mobile--JGJLR {
    overflow: visible;
    touch-action: auto
}
}
`, "",{"version":3,"sources":["webpack://./src/components/notificationCenter/NotificationCenter.pcss"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,MAAM;EACN,OAAO;EACP,mCAA2B;UAA3B,2BAA2B;EAC3B,iCAAiC;EACjC,aAAa;EACb,YAAY;EACZ,UAAU;EACV,UAAU;EACV,oCAAoC;EACpC,wBAAwB;EACxB,gBAAgB;EAChB,gBAAgB;AA0BlB;;EAxBE,2BAfF;IAgBI,6CAA6C;IAC7C,YAAY;IACZ,iBAAiB;IACjB,eAAe;IACf,kBAAkB;IAClB,6BAA6B;IAC7B,QAAQ;IACR,UAAU;IACV;;;;6CAIyC;IACzC,+BAA+B;IAC/B,gBAAgB;AASpB;AARE;;EAEA;IACE,kBAAkB;IAClB,cAAc;IACd,UAAU;IACV,mCAAmC;EACrC;;AAGF;EACE,kBAAkB;EAClB,wBAAwB;EACxB,iBAAiB;AACnB;;AAEA;EACE,gBAAgB;EAChB,kBAAkB;AAMpB;;AAJE;;AAJF;IAKI,iBAAiB;IACjB;AAEJ;AADE","sourcesContent":[".notification-center-wrapper {\n  position: fixed;\n  top: 0;\n  left: 0;\n  backface-visibility: hidden;\n  -webkit-overflow-scrolling: touch;\n  height: 100vh;\n  width: 100vw;\n  opacity: 1;\n  z-index: 1;\n  background-color: rgba(0, 0, 0, 0.5);\n  color: var(--text-color);\n  text-align: left;\n  overflow: hidden;\n\n  @mixin breakpoint {\n    box-shadow: 0 0 6.25rem 0 rgba(0, 0, 0, 0.15);\n    height: auto;\n    max-height: 100vh;\n    width: 23.75rem;\n    position: absolute;\n    background-color: transparent;\n    right: 0;\n    left: auto;\n    transition:\n      transform 0.2s var(--drawer-animation),\n      opacity 0.2s var(--drawer-animation),\n      margin-top var(--transition) linear,\n      visibility 0.2s var(--drawer-animation);\n    transform: translate3d(0, 0, 0);\n    overflow: hidden;\n  }\n\n  &.closed {\n    visibility: hidden;\n    display: block;\n    opacity: 0;\n    transform: translate3d(0, -100%, 0);\n  }\n}\n\n.notification-center {\n  position: relative;\n  background: var(--white);\n  padding: 1.875rem;\n}\n\n.prevent-scroll-on-mobile {\n  overflow: hidden;\n  touch-action: none;\n\n  @mixin breakpoint {\n    overflow: visible;\n    touch-action: auto;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"notification-center-wrapper": `NotificationCenter__notification-center-wrapper--wgFVa`,
	"notificationCenterWrapper": `NotificationCenter__notification-center-wrapper--wgFVa`,
	"closed": `NotificationCenter__closed--CO_2w`,
	"notification-center": `NotificationCenter__notification-center--hNTCx`,
	"notificationCenter": `NotificationCenter__notification-center--hNTCx`,
	"prevent-scroll-on-mobile": `NotificationCenter__prevent-scroll-on-mobile--JGJLR`,
	"preventScrollOnMobile": `NotificationCenter__prevent-scroll-on-mobile--JGJLR`
};
export default ___CSS_LOADER_EXPORT___;
