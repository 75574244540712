import OpenButton from '@/components/openButton/OpenButton';
import styles from '@/components/notificationClient/NotificationClient.pcss';
import NotificationCenter from '@/components/notificationCenter/NotificationCenter';
import { createElement } from '@/tools/createElement';
import NotificationComponent from '@/components/NotificationComponent';
import { NotificationComponentTools } from '@/types/types';
import EventTool from '@/tools/EventTool';

export default class NotificationClient extends NotificationComponent {
  private readonly notificationCenter: NotificationCenter;
  private readonly openButton: OpenButton;
  private readonly renderedContainer: HTMLDivElement;

  constructor(tools: NotificationComponentTools) {
    super(tools);
    this.notificationCenter = new NotificationCenter(tools);
    this.openButton = new OpenButton(tools);

    this.renderedContainer = createElement<HTMLDivElement>('div', [styles.container]);
  }

  public render(): HTMLDivElement {
    this.renderedContainer.appendChild(this.openButton.render());
    this.renderedContainer.appendChild(this.notificationCenter.render());

    return this.renderedContainer;
  }

  public closeNotificationCenter(): void {
    this.openStateTool.closeCenter();
  }

  public buttonColorChangedToBlack(): void {
    EventTool.dispatchButtonColorToBlackListener();
  }

  public buttonColorChangedToWhite(): void {
    EventTool.dispatchButtonColorToWhiteListener();
  }
}
