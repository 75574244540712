export default class DateTool {
  public static newerThanHourAgo(timestamp: number): boolean {
    const hour = 60 * 60 * 1000;

    return Date.now().valueOf() - timestamp * 1000 < hour;
  }

  public static isToday(timestamp: number): boolean {
    const today = new Date(Date.now().valueOf()).setHours(0, 0, 0, 0);
    const timestampDay = new Date(timestamp * 1000).setHours(0, 0, 0, 0);

    return today === timestampDay;
  }

  public static isYesterday(timestamp: number): boolean {
    const yesterday = new Date(Date.now().valueOf() - 1000 * 60 * 60 * 24).setHours(0, 0, 0, 0);
    const timestampDay = new Date(timestamp * 1000).setHours(0, 0, 0, 0);

    return yesterday === timestampDay;
  }

  public static isOlderThanYesterday(timestamp: number): boolean {
    return !DateTool.isToday(timestamp) && !DateTool.isYesterday(timestamp);
  }

  public static generateTime(timestamp: number): string {
    if (DateTool.isToday(timestamp)) {
      const now = Date.now().valueOf();
      const elapsed = now - timestamp * 1000;
      const msPerMinute = 60 * 1000;
      const msPerHour = msPerMinute * 60;

      if (DateTool.newerThanHourAgo(timestamp)) {
        const minutes = Math.round(elapsed / msPerMinute);
        return minutes === 1 ? '1 minuutti sitten' : `${minutes} minuuttia sitten`;
      }

      const hours = Math.round(elapsed / msPerHour);
      return hours === 1 ? '1 tunti sitten' : `${hours} tuntia sitten`;
    }

    if (DateTool.isYesterday(timestamp)) {
      const timestampDay = new Date(timestamp * 1000);
      return `klo ${timestampDay.getHours()}:${String(timestampDay.getMinutes()).padStart(2, '0')}`;
    }

    const timestampDay = new Date(timestamp * 1000);
    const numberToWeekdayMap: Record<number, string> = {
      0: 'sunnuntai',
      1: 'maanantai',
      2: 'tiistai',
      3: 'keskiviikko',
      4: 'torstai',
      5: 'perjantai',
      6: 'lauantai',
    };

    return `${numberToWeekdayMap[timestampDay.getDay()]} ${timestampDay.getDate()}.${
      timestampDay.getMonth() + 1
    }.${timestampDay.getFullYear()}`;
  }
}
