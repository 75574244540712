import { createElement } from '@/tools/createElement';
import styles from '@/components/loadingDots/LoadingDots.pcss';

export default class LoadingDots {
  public static render(): HTMLSpanElement {
    const dotWrapper = createElement('div', [styles.loadingDots]);

    const first = createElement('span', [styles.dot, styles.first]);
    dotWrapper.appendChild(first);

    const middle = createElement('span', [styles.dot, styles.middle]);
    dotWrapper.appendChild(middle);

    const last = createElement('span', [styles.dot, styles.last]);
    dotWrapper.appendChild(last);

    return dotWrapper;
  }
}
