import NotificationTool from '@/tools/NotificationTool';
import AnalyticsTool from '@/tools/AnalyticsTool';
import OpenStateTool from '@/tools/OpenStateTool';

export interface InitOptions {
  target: string;
  vertical: VerticalAnalyticsName;
  refreshTime?: number;
}

export enum Vertical {
  ot = 'oikotie',
  otas = 'apartment',
  otty = 'recruitment',
  otto = 'premises',
}

export type NotificationVertical = Exclude<Vertical, Vertical.otto>;

export type VerticalAnalyticsName = keyof typeof Vertical;

export enum DateGroup {
  today = 'today',
  yesterday = 'yesterday',
  older = 'older',
}

export interface NotificationData {
  id: number;
  title: string;
  target: string;
  vertical: NotificationVertical;
  timestamp: number;
  isNew: boolean;
}

export interface GroupedNotifications {
  today?: NotificationData[];
  yesterday?: NotificationData[];
  older?: NotificationData[];
}

export interface NotificationFeed {
  id: number;
  notification_type: number;
  timestamp: number;
  is_new: boolean;
  is_seen?: boolean;
  notification_title: string;
  notification_url: string;
  notification_vertical?: NotificationVertical;
  data: {
    id: number;
    name: string;
    event_type: number;
    event_data: Record<string, unknown>[];
    items_found: number;
    items: number[];
    url?: string;
  };
}

export interface NotificationComponentTools {
  notificationTool: NotificationTool;
  analyticsTool: AnalyticsTool;
  openStateTool: OpenStateTool;
}

export interface DataLayerEvent {
  event: string;
  notificationEvent: string;

  [key: string]: string | number;
}

export type MarkNotificationsData = {
  id: number;
  seen?: boolean;
  read?: boolean;
};
