import { DataLayerEvent, VerticalAnalyticsName } from '@/types/types';

declare global {
  interface Window {
    dataLayer?: DataLayerEvent[];
  }
}

export default class AnalyticsTool {
  private static openEventId = 'notificationOpened';
  private static clickEventId = 'notificationClicked';

  private readonly verticalDataName: VerticalAnalyticsName;
  private readonly eventName: string;

  constructor(verticalDataName: VerticalAnalyticsName) {
    this.verticalDataName = verticalDataName;
    this.eventName = `${verticalDataName}Event`;
  }

  public pushOpenEvent(unseen: number): void {
    this.pushEvent(AnalyticsTool.openEventId, {
      unseenNotifications: unseen,
    });
  }

  public pushClickEvent(position: number): void {
    this.pushEvent(AnalyticsTool.clickEventId, {
      notificationPosition: position,
    });
  }

  private pushEvent(eventName: string, data: { [key: string]: string | number }): void {
    const event: DataLayerEvent = {
      event: this.eventName,
      notificationEvent: eventName,
      ...data,
    };

    window.dataLayer?.push(event);
  }
}
