import NotificationTool from '@/tools/NotificationTool';
import AnalyticsTool from '@/tools/AnalyticsTool';
import { NotificationComponentTools } from '@/types/types';
import OpenStateTool from '@/tools/OpenStateTool';

export default abstract class NotificationComponent {
  protected readonly notificationTool: NotificationTool;
  protected readonly analyticsTool: AnalyticsTool;
  protected readonly tools: NotificationComponentTools;
  protected readonly openStateTool: OpenStateTool;

  protected constructor(tools: NotificationComponentTools) {
    this.notificationTool = tools.notificationTool;
    this.analyticsTool = tools.analyticsTool;
    this.openStateTool = tools.openStateTool;
    this.tools = tools;
  }

  abstract render(): HTMLElement;
}
