// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.NotificationListSections__section--fE1aL {
  margin-bottom: 1.875rem;
}

  .NotificationListSections__section--fE1aL.NotificationListSections__empty--UtaCy {
    margin-bottom: 0;
  }

  .NotificationListSections__section--fE1aL.NotificationListSections__init--JYtG7 {
    display: none;
  }

.NotificationListSections__title--DutVz {
  font-weight: var(--font-weight-bold);
  margin-bottom: 0.9375rem;
}

.NotificationListSections__empty--UtaCy .NotificationListSections__title--DutVz {
    margin-bottom: 0;
  }

.NotificationListSections__list--Qginy {
  margin: 0;
  padding: 0;
}

.NotificationListSections__list-item--tw6CR {
  display: block;
  margin-bottom: 0.9375rem;
}

.NotificationListSections__list-item--tw6CR:last-of-type {
    margin-bottom: 0;
  }
`, "",{"version":3,"sources":["webpack://./src/components/notificationListSection/NotificationListSections.pcss"],"names":[],"mappings":"AAAA;EACE,uBAAsB;AASxB;;EAPE;IACE,gBAAgB;EAClB;;EAEA;IACE,aAAa;EACf;;AAGF;EACE,oCAAoC;EACpC,wBAAsB;AAKxB;;AAHE;IACE,gBAAgB;EAClB;;AAGF;EACE,SAAS;EACT,UAAU;AACZ;;AAEA;EACE,cAAc;EACd,wBAAsB;AAKxB;;AAHE;IACE,gBAAgB;EAClB","sourcesContent":[".section {\n  margin-bottom: rem(30);\n\n  &.empty {\n    margin-bottom: 0;\n  }\n\n  &.init {\n    display: none;\n  }\n}\n\n.title {\n  font-weight: var(--font-weight-bold);\n  margin-bottom: rem(15);\n\n  .empty & {\n    margin-bottom: 0;\n  }\n}\n\n.list {\n  margin: 0;\n  padding: 0;\n}\n\n.list-item {\n  display: block;\n  margin-bottom: rem(15);\n\n  &:last-of-type {\n    margin-bottom: 0;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"section": `NotificationListSections__section--fE1aL`,
	"empty": `NotificationListSections__empty--UtaCy`,
	"init": `NotificationListSections__init--JYtG7`,
	"title": `NotificationListSections__title--DutVz`,
	"list": `NotificationListSections__list--Qginy`,
	"list-item": `NotificationListSections__list-item--tw6CR`,
	"listItem": `NotificationListSections__list-item--tw6CR`
};
export default ___CSS_LOADER_EXPORT___;
