import styles from '@/components/openButton/OpenButton.pcss';
import buttonStyles from '@/assets/styles/svg-button.pcss';
import { notificationIcon } from '@/assets/icons/icons';
import helperStyles from '@/assets/styles/helper.pcss';
import { createElement } from '@/tools/createElement';
import Counter from '@/components/counter/Counter';
import NotificationComponent from '@/components/NotificationComponent';
import { NotificationComponentTools } from '@/types/types';
import EventTool from '@/tools/EventTool';

export default class OpenButton extends NotificationComponent {
  private readonly button: HTMLButtonElement;
  private readonly counter: Counter;
  private readonly helperText = 'Avaa ilmoitukset';

  constructor(tools: NotificationComponentTools) {
    super(tools);
    this.button = createElement<HTMLButtonElement>('button', [styles.openButton, buttonStyles.svgButton]);
    this.counter = new Counter(tools, styles.counter);
  }

  public render(): HTMLButtonElement {
    EventTool.addButtonColorToBlackListener(this.buttonColorToBlack.bind(this));
    EventTool.addButtonColorToWhiteListener(this.buttonColorToWhite.bind(this));

    this.button.setAttribute('type', 'button');
    this.button.setAttribute('title', this.helperText);

    this.button.innerHTML = notificationIcon();

    const text = createElement('span', [helperStyles.visuallyHidden]);
    text.innerHTML = this.helperText;
    this.button.appendChild(text);

    this.button.appendChild(this.counter.init());

    this.addEventListeners();

    return this.button;
  }

  private addEventListeners(): void {
    this.button.addEventListener('click', () => {
      this.openStateTool.openCenter();
    });
  }

  private buttonColorToBlack(): void {
    this.button.classList.add(styles.black);
  }

  private buttonColorToWhite(): void {
    this.button.classList.remove(styles.black);
  }
}
