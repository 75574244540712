import styles from '@/components/notificationCenterHeader/NotificationCenterHeader.pcss';
import CloseButton from '@/components/closeButton/CloseButton';
import { notificationIcon } from '@/assets/icons/icons';
import { createElement } from '@/tools/createElement';
import NotificationComponent from '@/components/NotificationComponent';
import { NotificationComponentTools } from '@/types/types';

export default class NotificationCenterHeader extends NotificationComponent {
  private readonly closeButton: CloseButton;

  constructor(tools: NotificationComponentTools) {
    super(tools);
    this.closeButton = new CloseButton(tools);
  }

  public render(): HTMLDivElement {
    const header = createElement<HTMLDivElement>('div', [styles.header]);

    const titleWrapper = createElement('div', [styles.titleWrapper]);
    header.appendChild(titleWrapper);

    const icon = createElement('span', [styles.icon]);
    icon.innerHTML = notificationIcon();
    titleWrapper.appendChild(icon);

    const title = createElement('span', [styles.title]);
    title.innerText = 'Ilmoitukset';
    titleWrapper.appendChild(title);

    const button = this.closeButton.render();
    header.appendChild(button);

    return header;
  }
}
