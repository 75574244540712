// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.OpenButton__open-button--x1cIy {
  height: 100%;
  position: relative;
}

  .OpenButton__open-button--x1cIy svg {
    fill: var(--black);
  }

  .OpenButton__open-button--x1cIy:not(.OpenButton__black--yVGBt):hover:after {
      background-color: rgba(255, 255, 255, 0.15);
    }

  .OpenButton__open-button--x1cIy:not(.OpenButton__black--yVGBt) svg {
      fill: var(--white);
    }

.OpenButton__counter--mvc_N {
  position: absolute;
  top: -0.375rem;
  right: -0.75rem;
}
`, "",{"version":3,"sources":["webpack://./src/components/openButton/OpenButton.pcss"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,kBAAkB;AAepB;;EAbE;IACE,kBAAkB;EACpB;;EAGE;MACE,2CAA2C;IAC7C;;EAEA;MACE,kBAAkB;IACpB;;AAIJ;EACE,kBAAkB;EAClB,cAAc;EACd,eAAe;AACjB","sourcesContent":[".open-button {\n  height: 100%;\n  position: relative;\n\n  svg {\n    fill: var(--black);\n  }\n\n  &:not(.black) {\n    &:hover:after {\n      background-color: rgba(255, 255, 255, 0.15);\n    }\n\n    svg {\n      fill: var(--white);\n    }\n  }\n}\n\n.counter {\n  position: absolute;\n  top: -0.375rem;\n  right: -0.75rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"open-button": `OpenButton__open-button--x1cIy`,
	"openButton": `OpenButton__open-button--x1cIy`,
	"black": `OpenButton__black--yVGBt`,
	"counter": `OpenButton__counter--mvc_N`
};
export default ___CSS_LOADER_EXPORT___;
