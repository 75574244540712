export default class EventTool {
  /**
   * Notification client opened.
   */
  private static notificationClientOpenedEventId = 'notification-client-opened';

  private static notificationClientOpenedEvent = new Event(EventTool.notificationClientOpenedEventId);

  public static dispatchNotificationClientOpenedEvent(): void {
    window.dispatchEvent(EventTool.notificationClientOpenedEvent);
  }

  public static addNotificationClientOpenedListener(callback: () => void): void {
    window.addEventListener(EventTool.notificationClientOpenedEventId, callback, false);
  }

  /**
   * Notification client closed.
   */
  private static notificationClientClosedEventId = 'notification-client-closed';

  private static notificationClientClosedEvent = new Event(EventTool.notificationClientClosedEventId);

  public static dispatchNotificationClientClosedEvent(): void {
    window.dispatchEvent(EventTool.notificationClientClosedEvent);
  }

  public static addNotificationClientClosedListener(callback: () => void): void {
    window.addEventListener(EventTool.notificationClientClosedEventId, callback, false);
  }

  /**
   * Notifications fetched.
   */
  private static notificationsFetchedEventId = 'notifications-fetched';

  private static notificationsFetchedEvent = new Event(EventTool.notificationsFetchedEventId);

  public static dispatchNotificationsFetchedListener(): void {
    window.dispatchEvent(EventTool.notificationsFetchedEvent);
  }

  public static addNotificationsFetchedListener(callback: () => void): void {
    window.addEventListener(EventTool.notificationsFetchedEventId, callback, false);
  }

  public static removeNotificationsFetchedListener(callback: () => void): void {
    window.removeEventListener(EventTool.notificationsFetchedEventId, callback, false);
  }

  /**
   * Next page fetched.
   */
  private static nextPageFetchedEventId = 'next-page-fetched';

  private static nextPageFetchedEvent = new Event(EventTool.nextPageFetchedEventId);

  public static dispatchNextPageFetchedListener(): void {
    window.dispatchEvent(EventTool.nextPageFetchedEvent);
  }

  public static addNextPageFetchedListener(callback: () => void): void {
    window.addEventListener(EventTool.nextPageFetchedEventId, callback, false);
  }

  public static removeNextPageFetchedListener(callback: () => void): void {
    window.removeEventListener(EventTool.nextPageFetchedEventId, callback, false);
  }

  /**
   * New notifications fetched.
   */
  private static newNotificationsFetchedEventId = 'new-notifications-fetched';

  private static newNotificationsFetchedEvent = new Event(EventTool.newNotificationsFetchedEventId);

  public static dispatchNewNotificationsFetchedListener(): void {
    window.dispatchEvent(EventTool.newNotificationsFetchedEvent);
  }

  public static addNewNotificationsFetchedListener(callback: () => void): void {
    window.addEventListener(EventTool.newNotificationsFetchedEventId, callback, false);
  }

  /**
   * Unseen count updated.
   */
  private static unseenCountUpdatedEventId = 'unseen-count-updated';

  private static unseenCountUpdatedEvent = new Event(EventTool.unseenCountUpdatedEventId);

  public static dispatchUnseenCountUpdatedListener(): void {
    window.dispatchEvent(EventTool.unseenCountUpdatedEvent);
  }

  public static addUnseenCountUpdatedListener(callback: () => void): void {
    window.addEventListener(EventTool.unseenCountUpdatedEventId, callback, false);
  }

  /**
   * Button color to black.
   */
  private static buttonColorToBlackId = 'button-color-to-black';

  private static buttonColorToBlackEvent = new Event(EventTool.buttonColorToBlackId);

  public static dispatchButtonColorToBlackListener(): void {
    window.dispatchEvent(EventTool.buttonColorToBlackEvent);
  }

  public static addButtonColorToBlackListener(callback: () => void): void {
    window.addEventListener(EventTool.buttonColorToBlackId, callback, false);
  }

  /**
   * Button color to white.
   */
  private static buttonColorToWhiteId = 'button-color-to-white';

  private static buttonColorToWhiteEvent = new Event(EventTool.buttonColorToWhiteId);

  public static dispatchButtonColorToWhiteListener(): void {
    window.dispatchEvent(EventTool.buttonColorToWhiteEvent);
  }

  public static addButtonColorToWhiteListener(callback: () => void): void {
    window.addEventListener(EventTool.buttonColorToWhiteId, callback, false);
  }
}
