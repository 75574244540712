import { LatestNotificationApiResponse } from '@/resources/types';
import ApiResource from '@/resources/ApiResource';
import { AxiosHeaders } from 'axios';

const BASE_URL = process.env.OIKOTIE_API_URL;

export default class TokenValidator {
  private readonly apiResource: ApiResource;

  constructor() {
    this.apiResource = new ApiResource();
  }

  async isTokenValid(token: string | null): Promise<boolean> {
    if (!token) {
      return false;
    }

    return await this.validateAgainstEndpoint(token);
  }

  private async validateAgainstEndpoint(token: string): Promise<boolean> {
    const requestUrl = `${BASE_URL}user/notifications/latest`;

    try {
      await this.apiResource.getData<LatestNotificationApiResponse>(
        requestUrl,
        false,
        new AxiosHeaders({ 'ot-ns-token': token }),
      );
      return true;
    } catch (e) {
      return false;
    }
  }
}
